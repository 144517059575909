import React from 'react';
import { Link } from 'react-router-dom';
import { FaCamera, FaGift, FaBox, FaRegHandshake } from 'react-icons/fa'; // Import Font Awesome icons
import Navbar from '../constants/Navbar';
import Footer from '../constants/Footer';
import ProgressSlider from '../components/ProgressSlider';

const Services = () => {
    const services = [
        {
            title: 'Wedding Photography',
            description:
                'Capture your once-in-a-lifetime moments with high-quality photos. From candid moments to posed shots, we ensure every detail is perfectly preserved.',
            icon: <FaGift className="text-5xl text-indigo-800" />,
            bgColor: 'bg-gradient-to-r from-indigo-100 to-indigo-300',
            features: [
                'Candid and posed shots',
                'High-resolution images',
                'Customized packages',
                'Full-day coverage',
            ],
            cta: 'Book Wedding Shoot',
        },
        {
            title: 'Portrait Photography',
            description:
                'Capture timeless memories with personalized portraits that reflect your unique story.',
            icon: <FaCamera className="text-5xl text-green-700" />,
            bgColor: 'bg-gradient-to-r from-green-100 to-green-300',
            features: [
                'Personalized sessions tailored to your style',
                'High-resolution images for print and digital use',
                'Flexible scheduling to suit your needs',
                'Professional retouching for flawless portraits',
            ],
            cta: 'Book Portrait Shoot',
        },
        {
            title: 'Fashion Photography',
            description:
                'Showcase your style and creativity with professional fashion shoots tailored for brands, designers, and models.',
            icon: <FaCamera className="text-5xl text-blue-600" />,
            bgColor: 'bg-gradient-to-r from-blue-100 to-blue-300',
            features: [
                'Creative direction and styling to match your vision',
                'High-quality, magazine-style photos',
                'Location shoot',
                'Fast turnaround with fully edited images',
            ],
            cta: 'Book Fashion Shoot',
        },
        {
            title: 'Event Photography',
            description:
                'Relive your special moments with stunning photography that captures every detail and emotion.',
            icon: <FaRegHandshake className="text-5xl text-pink-700" />,
            bgColor: 'bg-gradient-to-r from-pink-100 to-pink-300',
            features: [
                'Coverage for all types of events',
                'Candid and posed shots capturing every moment',
                'Customizable packages for your event needs',
                'Professional equipment to ensure high-quality images',
            ],
            cta: 'Book Event',
        },
        {
            title: 'Maternity Photography',
            description:
                'Celebrate the beauty of motherhood with elegant and heartwarming maternity photoshoots.',
            icon: <FaCamera className="text-5xl text-orange-600" />,
            bgColor: 'bg-gradient-to-r from-orange-100 to-orange-300',
            features: [
                'Natural and elegant poses to highlight your journey',
                'Indoor and outdoor shoot flexibility',
                'Retouched, high-quality images that preserve the beauty of motherhood',
            ],
            cta: 'Book Maternity Shoot',
        },
        {
            title: 'Product Photography',
            description:
                'Showcase your products with eye-catching visuals, perfect for e-commerce, advertising, and marketing.',
            icon: <FaBox className="text-5xl text-purple-700" />,
            bgColor: 'bg-gradient-to-r from-purple-100 to-purple-300',
            features: [
                'Professional studio setup',
                'High-quality lighting',
                'Perfect for online stores',
                'Detailed close-up shots',
            ],
            cta: 'Showcase Products',
        },
    ];

    return (
        <>
            <Navbar className="mb-10" />
            <div className="py-16 my-10 px-4 bg-gray-50 selection:bg-cyan-300 selection:text-cyan-700">
                <div className="flex flex-wrap justify-center items-stretch gap-8">
                    {services.map((service, index) => (
                        <div
                            key={index}
                            className={`w-full sm:w-80 md:w-64 p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 ${service.bgColor} min-h-[400px] flex flex-col`}
                        >
                            {/* Service Icon */}
                            <div className="text-center mb-4 flex justify-center">
                                {service.icon}
                            </div>
                            {/* Service Title */}
                            <h3 className="text-2xl font-semibold text-center text-purple-800 mb-4">
                                {service.title}
                            </h3>
                            {/* Service Description */}
                            <p className="text-center text-gray-700 text-sm mb-4">
                                {service.description}
                            </p>
                            {/* Features List */}
                            <ul className="text-left text-gray-600 text-sm mb-6 mx-4 flex-grow">
                                {service.features.map((feature, idx) => (
                                    <li key={idx} className="mb-2 flex items-center">
                                        <span className="mr-2 text-purple-800">•</span>
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                            {/* Call-to-Action Button */}
                            <div className="text-center">
                                <Link
                                    to="/getintouch"
                                    className="w-full py-2 px-4 rounded-md text-white bg-purple-800 hover:bg-purple-700 transition-colors mb-4 inline-block"
                                >
                                    {service.cta}
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
            <ProgressSlider />
        </>
    );
};

export default Services;
