import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faMeta, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Logo from '../assets/logo/EditorAruLogoPurple.png'; // Ensure the path is correct
import { Link } from 'react-router-dom';

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};

const Footer = () => {
    return (
        <footer className="bg-[#fff9f3] text-[#333] py-8 selection:bg-cyan-300 selection:text-cyan-700">
            <div className="container mx-auto px-6">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 items-start">
                    {/* Left Section - Logo and Description */}
                    <div className="flex flex-col items-start">
                        <Link to="/" onClick={scrollToTop}>
                            <img
                                src={Logo}
                                alt="EditorAru Logo"
                                className="h-14 mb-3"
                                loading="lazy" // Lazy loading for faster load times
                            />
                        </Link>
                        <p className="text-gray-600 text-sm">
                            Capture moments and create memories. <br /> Discover professional photography services!
                        </p>
                    </div>

                    {/* Middle Section - Quick Links */}
                    <div>
                        <h3 className="text-lg font-semibold mb-4 text-purple-800">Quick Links</h3>
                        <ul className="space-y-2">
                            {[
                                { label: 'Services', to: '/services' },
                                { label: 'Portfolio', to: '/gallery' },
                                { label: 'Contact', to: '/getintouch' },
                                { label: 'Products', to: '/Products' },
                            ].map(({ label, to }) => (
                                <li key={label}>
                                    <Link
                                        to={to}
                                        className="text-gray-600 hover:text-purple-800 transition duration-300"
                                        onClick={scrollToTop}
                                    >
                                        {label}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* Right Section - Social Icons */}
                    <div>
                        <h3 className="text-lg font-semibold mb-4 text-purple-800">Follow Us</h3>
                        <div className="flex space-x-6">
                            {[
                                { href: 'https://www.facebook.com/Editoraruu?mibextid=ZbWKwL', icon: faMeta, label: 'Facebook' },
                                { href: 'https://www.instagram.com/editoraru?igsh=dXJ4ZmJ3NHJtNW9r', icon: faInstagram, label: 'Instagram' },
                                { href: 'https://wa.me/message/G5MWMW3SE727A1', icon: faWhatsapp, label: 'Whatsapp' },
                            ].map(({ href, icon, label }) => (
                                <a
                                    key={label}
                                    href={href}
                                    aria-label={label}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-2xl text-gray-600 hover:text-purple-800 transition duration-300"
                                >
                                    <FontAwesomeIcon icon={icon} />
                                </a>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Bottom Section - Copyright */}
                <div className="mt-8 text-center text-gray-600 text-xs border-t border-gray-400 pt-4">
                    &copy; {new Date().getFullYear()}
                    <span className="text-purple-800 font-semibold"> EditorAru </span>
                    by{' '}
                    <a
                        href="https://nexkites.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-cyan-400 font-semibold hover:underline"
                    >
                        Nexkites
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
