import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Gallery from './pages/Gallery';
import Pricing from './pages/Pricing';
import Stories from './pages/Stories';
import Services from './pages/Services';
import GetInTouch from './pages/GetInTouch';
import Products from "./pages/Products";
import Bag from './pages/Bag';
import Booking from './pages/Booking';
import PhotoFrameOrder from './pages/PhotoFrameOrder';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />, // You need to pass a component here.
  },
  {
    path: 'gallery',
    element: <Gallery />
  },
  {
    path: 'pricing',
    element: <Pricing />
  },
  {
    path: 'stories',
    element: <Stories />
  },
  {
    path: 'Services',
    element: <Services />
  },
  {
    path: 'getintouch',
    element: <GetInTouch />
  },
  {
    path: 'Products',
    element: <Products />
  },
  {
    path: 'Bag',
    element: <Bag />
  },
  {
    path: 'Booking',
    element: <Booking />
  },
  {
    path: 'PhotoFrameOrder',
    element: <PhotoFrameOrder />
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
