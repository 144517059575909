import React, { useState } from "react";


const PhotoFrameOrder = () => {
    const [productName, setProductName] = useState("Awesome Product");
    const [quantity, setQuantity] = useState(1);
    const ownerWhatsApp = "917066917801"; // Replace with owner's WhatsApp number (with country code)

    const handleOrder = () => {
        const message = `Hello, I would like to order ${quantity} unit(s) of ${productName}.`;
        const whatsappURL = `https://wa.me/${ownerWhatsApp}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, "_blank");
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
            <div className="max-w-md w-full bg-white shadow-lg rounded-lg p-6">
                <h1 className="text-2xl font-bold mb-4">Order Product</h1>
                <div className="mb-4">
                    <label htmlFor="product" className="block text-sm font-medium text-gray-700">
                        Product Name
                    </label>
                    <input
                        type="text"
                        id="product"
                        value={productName}
                        onChange={(e) => setProductName(e.target.value)}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="quantity" className="block text-sm font-medium text-gray-700">
                        Quantity
                    </label>
                    <input
                        type="number"
                        id="quantity"
                        value={quantity}
                        min="1"
                        onChange={(e) => setQuantity(e.target.value)}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
                <button
                    onClick={handleOrder}
                    className="w-full bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                >
                    Order Now
                </button>
            </div>
        </div>
    );
};

export default PhotoFrameOrder;
