import React, { useState } from 'react';
import Navbar from '../constants/Navbar';
import Footer from '../constants/Footer';
import SliderIMG1 from "../assets/slider/1.jpg";
import SliderIMG2 from "../assets/slider/2.jpg";
import SliderIMG3 from "../assets/slider/3.jpg";
import SliderIMG4 from "../assets/slider/4.jpg";
import SliderIMG5 from "../assets/slider/5.jpg";
import SliderIMG6 from "../assets/slider/6.jpg";
import SliderIMG7 from "../assets/slider/7.jpg";
import SliderIMG8 from "../assets/slider/8.jpg";
import SliderIMG9 from "../assets/slider/9.jpg";
import SliderIMG10 from "../assets/slider/10.jpg";
import SliderIMG11 from "../assets/slider/11.jpg";
import SliderIMG12 from "../assets/slider/12.jpg";
import SliderIMG13 from "../assets/slider/13.jpg";
import SliderIMG14 from "../assets/slider/14.jpg";
import SliderIMG15 from "../assets/slider/15.jpg";
import SliderIMG16 from "../assets/slider/16.jpg";
import SliderIMG17 from "../assets/slider/17.jpg";
import SliderIMG19 from "../assets/slider/19.jpg";
import SliderIMG20 from "../assets/slider/20.jpg";
import ProgressSlider from '../components/ProgressSlider';

// Sample image data
const images = [
    SliderIMG1,
    SliderIMG2,
    SliderIMG3,
    SliderIMG4,
    SliderIMG5,
    SliderIMG6,
    SliderIMG7,
    SliderIMG8,
    SliderIMG9,
    SliderIMG10,
    SliderIMG11,
    SliderIMG12,
    SliderIMG13,
    SliderIMG14,
    SliderIMG15,
    SliderIMG16,
    SliderIMG17,
    SliderIMG19,
    SliderIMG20
];

const PhotoGallery = () => {
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    const handleCloseModal = () => {
        setSelectedImage(null);
    };

    const handleBackgroundClick = (e) => {
        if (e.target.id === 'modal-background') {
            setSelectedImage(null);
        }
    };

    return (
        <>
            <Navbar className="mb-10" />
            <section className="py-12 px-4 mt-10 bg-[radial-gradient(circle_800px_at_100%_200px,#d5c5ff,transparent)] selection:bg-cyan-300 selection:text-cyan-700">
                <h2 className="text-4xl text-center text-purple-800 font-bold mb-8">Gallery</h2>
                {/* Responsive Grid: 1 column on small screens, 2 on medium, 3 on large, etc. */}
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                    {images.map((image, index) => (
                        <div
                            key={index}
                            className="group overflow-hidden rounded-lg shadow-lg transition-transform transform hover:scale-105"
                        >
                            <img
                                src={image}
                                alt={`Gallery Image ${index + 1}`}
                                className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110 cursor-pointer"
                                onClick={() => handleImageClick(image)}
                                loading="lazy"
                                srcSet={`${image}?w=400 400w, ${image}?w=800 800w, ${image}?w=1200 1200w`}
                                sizes="(max-width: 768px) 400px, (max-width: 1200px) 800px, 1200px"
                            />
                        </div>
                    ))}
                </div>

                {/* Modal for image display */}
                {selectedImage && (
                    <div
                        id="modal-background"
                        className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4"
                        onClick={handleBackgroundClick}
                    >
                        <div className="relative bg-white rounded-lg shadow-lg p-4 animate-fadeIn">
                            <img
                                src={selectedImage}
                                alt="Selected"
                                className="max-w-[90vw] max-h-[80vh] object-contain rounded-lg"
                            />
                            <button
                                className="absolute top-2 right-2 text-white text-2xl bg-purple-800 rounded-full p-1 hover:bg-gray-400 transition"
                                onClick={handleCloseModal}
                            >
                                &times;
                            </button>
                        </div>
                    </div>
                )}
            </section>
            <Footer />
            <ProgressSlider />
        </>
    );
};

export default PhotoGallery;
