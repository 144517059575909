import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../constants/Navbar";
import Footer from "../constants/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMeta, faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Booking = () => {
    const { productId } = useParams();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        instructions: "",
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = "Name is required.";
        if (!formData.email) newErrors.email = "Email is required.";
        if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = "Please enter a valid email address.";
        return newErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        setIsSubmitted(true);
    };

    const closePopup = () => {
        setIsSubmitted(false);
    };

    return (
        <>
            <Navbar className="mb-16" />
            <div className="min-h-screen mt-10 bg-gray-100 py-16 px-8 selection:bg-cyan-300 selection:text-cyan-700">
                <div className="max-w-5xl mx-auto flex flex-col lg:flex-row gap-16">
                    {/* Left Section: Booking Form */}
                    <div className="flex-1 bg-white p-8 rounded-lg shadow-lg">
                        <h1 className="text-2xl font-bold mb-4">Customize Your Artwork</h1>
                        <p className="text-gray-600 mb-6">
                            You're customizing the product with ID: <strong>{productId}</strong>
                        </p>

                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
                                    Your Name
                                </label>
                                <input
                                    type="text"
                                    id="name"
                                    className={`w-full border ${errors.name ? 'border-red-500' : 'border-gray-300'} p-3 rounded-lg`}
                                    placeholder="Enter your name"
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                                {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
                            </div>

                            <div className="mb-4">
                                <label htmlFor="email" className="block text-gray-700 font-bold mb-2">
                                    Email Address
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    className={`w-full border ${errors.email ? 'border-red-500' : 'border-gray-300'} p-3 rounded-lg`}
                                    placeholder="Enter your email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                            </div>

                            <div className="mb-4">
                                <label htmlFor="instructions" className="block text-gray-700 font-bold mb-2">
                                    Special Instructions
                                </label>
                                <textarea
                                    id="instructions"
                                    className="w-full border border-gray-300 p-3 rounded-lg"
                                    rows="4"
                                    placeholder="Describe how you'd like to customize your artwork"
                                    value={formData.instructions}
                                    onChange={handleChange}
                                ></textarea>
                            </div>

                            <button
                                type="submit"
                                className="w-full bg-purple-800 text-white py-3 rounded-lg hover:bg-purple-700 transition"
                            >
                                Submit Customization Request
                            </button>
                        </form>
                    </div>

                    {/* Right Section: Company Details */}
                    <div className="flex-1 bg-white p-8 rounded-lg shadow-lg">
                        <h2 className="text-xl font-bold mb-4">Company Address</h2>
                        <h3 className="text-xl font-medium text-purple-800 mb-4">
                            Editoraru Photography
                        </h3>
                        <p className="text-gray-600 mb-6">
                            CIDCO N-9, <br />
                            Chh. Sambhajinagar, MH. IN - 431003
                        </p>

                        <h3 className="text-xl font-medium text-purple-800 mb-2">Contact Us</h3>
                        <p className="text-gray-600">
                            For inquiries, feel free to reach us via WhatsApp or email.
                            <br />
                            <span className="font-semibold">Phone:</span> +91 70669 17801
                            <br />
                            <span className="font-semibold">Email:</span> editoraru@gmail.com
                        </p>

                        {/* Social Media Links and WhatsApp */}
                        <div className="mt-6 flex space-x-6 justify-center">
                            <a
                                href="https://wa.me/message/G5MWMW3SE727A1"
                                aria-label="WhatsApp"
                                className="p-3 rounded-full shadow-lg bg-green-600 text-white hover:bg-green-500 transition duration-200"
                            >
                                <FontAwesomeIcon icon={faWhatsapp} className="text-2xl" />
                            </a>
                            <a
                                href="https://www.facebook.com/Editoraruu?mibextid=ZbWKwL"
                                aria-label="Facebook"
                                className="p-3 rounded-full shadow-lg bg-blue-600 text-white hover:bg-blue-500 transition duration-200"
                            >
                                <FontAwesomeIcon icon={faMeta} className="text-2xl" />
                            </a>
                            <a
                                href="https://www.instagram.com/editoraru?igsh=dXJ4ZmJ3NHJtNW9r"
                                aria-label="Instagram"
                                className="p-3 rounded-full shadow-lg bg-pink-600 text-white hover:bg-pink-500 transition duration-200"
                            >
                                <FontAwesomeIcon icon={faInstagram} className="text-2xl" />
                            </a>
                        </div>

                        <div className="mt-8 text-center">
                            <h3 className="text-lg font-semibold text-purple-800">
                                For quicker responses, contact us on WhatsApp!
                            </h3>
                        </div>
                    </div>
                </div>
            </div>

            {/* Success Modal */}
            {isSubmitted && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-8 rounded-lg shadow-lg w-80">
                        <h3 className="text-xl font-bold text-green-600">Message Sent!</h3>
                        <p className="text-gray-700 mt-4">
                            Your message has been successfully sent. We'll get back to you soon!
                        </p>
                        <button
                            onClick={closePopup}
                            className="mt-6 w-full bg-green-600 hover:bg-green-500 text-white py-2 rounded-lg"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}

            <Footer />
        </>
    );
};

export default Booking;
