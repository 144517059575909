import React, { lazy, Suspense, useState } from 'react';
import Preloader from './components/Preloader';
import Products from './pages/Products';

// Lazy load your components
const Navbar = lazy(() => import('./constants/Navbar'));
const Footer = lazy(() => import('./constants/Footer'));
const Hero = lazy(() => import('./components/Hero'));
const About = lazy(() => import('./components/About'));
const ProductSlider = lazy(() => import('./components/ProductSlider'));
const PortraitsHides = lazy(() => import('./components/PortraitsHides'));
const ProgressSlider = lazy(() => import('./components/ProgressSlider'));

// Error Boundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.error("Error caught by boundary: ", error, info);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong. Please try again later.</h1>;
    }

    return this.props.children;
  }
}

const App = () => {
  const [bag, setBag] = useState([]);

  // Function to add product to the bag
  const addToBag = (product) => {
    setBag((prevBag) => [...prevBag, product]);
  };

  return (
    <div className="bg-[radial-gradient(circle_800px_at_100%_200px,#d5c5ff,transparent)] overflow-x-hidden antialiased selection:bg-cyan-300 selection:text-cyan-700">
      <ErrorBoundary>
        <Suspense fallback={<Preloader />}>
          <Navbar bagCount={bag.length} /> {/* Pass the bag count to Navbar */}
          <Hero />
          <PortraitsHides />
          <About />
          <ProductSlider />
          <Footer />
          <ProgressSlider />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};

export default App;
