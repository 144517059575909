// Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faMeta, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'; // Correct icon imports
import Navlogo from '../assets/logo/EditorAruLogoPurple.png';

const Navbar = ({ bagCount }) => {
  // State to handle menu toggle
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };


  return (
    <nav className="flex items-center justify-between p-5 bg-[#fff9f3] text-purple-800 fixed top-0 left-0 w-full z-50 shadow-lg rounded-b-xl selection:bg-cyan-300 selection:text-cyan-700" style={{ height: '80px' }}>
      {/* Left navigation links for larger screens */}
      <div className="hidden md:flex space-x-6 text-lg font-semibold items-center">
        <Link to="/gallery">Gallery</Link>
        <Link to="/services">Services</Link>
        <Link to="/Products">Products</Link>
      </div>

      {/* Logo */}
      <div className="flex justify-center items-center flex-grow">
        <Link to="/">
          <img src={Navlogo} alt="EditorAru logo" className="h-16" />
        </Link>
      </div>

      {/* Hamburger menu for small screens */}
      <div className="md:hidden flex items-center space-x-4">
        <button onClick={toggleMenu} className="text-purple-800">
          <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} className="text-2xl" />
        </button>
      </div>

      {/* Right navigation links for larger screens */}
      <div className="hidden md:flex items-center space-x-6">
        <div className="flex space-x-4">
          <a href="https://www.facebook.com/Editoraruu?mibextid=ZbWKwL">
            <FontAwesomeIcon icon={faMeta} className="text-xl hover:text-purple-800" />
          </a>
          <a href="https://www.instagram.com/editoraru?igsh=dXJ4ZmJ3NHJtNW9r">
            <FontAwesomeIcon icon={faInstagram} className="text-xl hover:text-purple-800" />
          </a>
          <a href="https://wa.me/message/G5MWMW3SE727A1">
            <FontAwesomeIcon icon={faWhatsapp} className="text-xl hover:text-purple-800" />
          </a>
        </div>



        <button className="p-3 bg-purple-800 text-white font-semibold rounded-lg hover:bg-purple-700">
          <Link to="/getintouch">Book Your Shoot</Link>
        </button>
      </div>

      {/* Mobile Menu */}
      <div className={`${isMenuOpen ? 'block' : 'hidden'} md:hidden absolute top-20 left-0 w-full bg-[#fff9f3] p-5 shadow-lg`}>
        <div className="flex flex-col space-y-4 items-center">
          <Link to="/gallery" onClick={toggleMenu}>Gallery</Link>
          <Link to="/services" onClick={toggleMenu}>Services</Link>
          <Link to="/Products" onClick={toggleMenu}>Products</Link>
          <div className="flex space-x-4">
            <a href="https://www.facebook.com/Editoraruu?mibextid=ZbWKwL" onClick={toggleMenu}>
              <FontAwesomeIcon icon={faMeta} className="text-xl hover:text-purple-800" />
            </a>
            <a href="https://www.instagram.com/editoraru?igsh=dXJ4ZmJ3NHJtNW9r" onClick={toggleMenu}>
              <FontAwesomeIcon icon={faInstagram} className="text-xl hover:text-purple-800" />
            </a>
            <a href="https://wa.me/message/G5MWMW3SE727A1" onClick={toggleMenu}>
              <FontAwesomeIcon icon={faWhatsapp} className="text-xl hover:text-purple-800" />
            </a>
          </div>



          <button className="p-3 bg-purple-800 text-white font-semibold rounded-lg hover:bg-purple-700">
            <Link to="/getintouch" onClick={toggleMenu}>Book Your Shoot</Link>
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
