import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../constants/Navbar";
import Footer from "../constants/Footer";

const Bag = ({ bagItems, updateQuantity, removeItem, calculateTotal }) => {
  return (
    <>
      <Navbar />
      <div className="bg-gray-100 min-h-screen py-10">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-center mb-8">Your Bag</h2>
          {bagItems.length > 0 ? (
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
              <div className="lg:col-span-2 space-y-6">
                {bagItems.map((item) => (
                  <div
                    key={item.id}
                    className="bg-white p-4 rounded-lg shadow-lg flex items-center justify-between"
                  >
                    <img
                      src={item.image}
                      alt={item.name}
                      className="w-20 h-20 object-cover rounded-lg"
                    />
                    <div className="flex-1 px-4">
                      <h3 className="text-lg font-semibold">{item.name}</h3>
                      <p className="text-gray-600">Price: ₹{item.price}</p>
                      <div className="flex items-center mt-2">
                        <button
                          className="px-3 py-1 bg-gray-200 rounded-lg"
                          onClick={() => updateQuantity(item.id, -1)}
                        >
                          -
                        </button>
                        <span className="mx-3 text-lg">{item.quantity}</span>
                        <button
                          className="px-3 py-1 bg-gray-200 rounded-lg"
                          onClick={() => updateQuantity(item.id, 1)}
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <button
                      className="text-red-500 hover:text-red-700"
                      onClick={() => removeItem(item.id)}
                    >
                      Remove
                    </button>
                  </div>
                ))}
              </div>
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-xl font-bold mb-4">Order Summary</h3>
                <div className="space-y-4">
                  <div className="flex justify-between">
                    <span>Subtotal</span>
                    <span>₹{calculateTotal()}</span>
                  </div>
                  <div className="flex justify-between">
                    <span>Shipping</span>
                    <span>₹500</span>
                  </div>
                  <div className="flex justify-between font-bold">
                    <span>Total</span>
                    <span>₹{calculateTotal() + 500}</span>
                  </div>
                </div>
                <button className="w-full mt-6 bg-purple-800 text-white py-2 rounded-lg hover:bg-purple-700 transition">
                  Checkout
                </button>
                <Link
                  to="/"
                  className="block text-center text-purple-500 mt-4 hover:underline"
                >
                  Continue Shopping
                </Link>
              </div>
            </div>
          ) : (
            <div className="text-center text-gray-600">
              <p>Your bag is empty.</p>
              <Link
                to="/products"
                className="mt-4 inline-block bg-purple-800 text-white py-2 px-6 rounded-lg hover:bg-purple-700 transition"
              >
                Explore Products
              </Link>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Bag;
