import React, { useEffect, useState } from 'react';
import LoaderImg from "../assets/logo/EditorAruLogoPurple.png";

function Preloader() {
  const [isLoading, setIsLoading] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    // Set preloader duration
    const timer = setTimeout(() => {
      setFadeOut(true); // Start fade-out animation
      setTimeout(() => setIsLoading(false), 300); // Wait for fade-out to complete
    }, 3000); // Preloader duration: 3 seconds

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, []);

  if (!isLoading) {
    return null; // Remove preloader once loading is complete
  }

  return (
    <div
      className={`preloader-container fixed inset-0 flex justify-center items-center bg-gradient-to-b from-purple-300 via-purple-100 to-white ${fadeOut ? 'opacity-0 translate-y-5 transition-all duration-500' : ''
        }`}
    >
      <div className="flex flex-col items-center">
        {/* Logo */}
        <img
          src={LoaderImg}
          alt="Logo"
          className="w-40 h-40 sm:w-48 sm:h-48 md:w-56 md:h-56 animate-pulse"
        />

        {/* Animated Loading Text */}
        <p className="text-xl sm:text-2xl md:text-3xl font-800 text-purple-800 mt-6">
          By <span className="text-gray-600 font-800">Aryan Sadashiv</span>
        </p>


      </div>
    </div>
  );
}

export default Preloader;
