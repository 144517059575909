import React, { useState } from "react";
import { Link } from "react-router-dom";
import ProdIMG1 from "../assets/products/Babasaheb frame.jpg";
import ProdIMG2 from "../assets/products/BestiesForever.jpg";
import ProdIMG3 from "../assets/products/Birthday Frame.jpg";
import ProdIMG4 from "../assets/products/Our Love Story.jpg";
import ProdIMG5 from "../assets/products/Aarti Portrait.jpg";

import Navbar from "../constants/Navbar";
import Footer from "../constants/Footer";

const ProductShowcase = () => {
  const [wishlisted, setWishlisted] = useState({});
  const [modalImage, setModalImage] = useState(null);

  const handleWishlistClick = (productId) => {
    setWishlisted((prev) => ({ ...prev, [productId]: !prev[productId] }));
  };

  const handleImageClick = (image) => {
    setModalImage(image);
  };

  const handleCloseModal = () => {
    setModalImage(null);
  };

  const handleOrder = (product) => {
    const ownerWhatsApp = "917066917801"; // Replace with the owner's WhatsApp number
    const message = `Hello, I would like to order the product "${product.name}" for ₹${product.price}. Size: ${product.sizes}.`;
    const whatsappURL = `https://wa.me/${ownerWhatsApp}?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, "_blank");
  };

  const products = {
    portraits: [
      {
        name: "Dr. Babasaheb Ambedkar",
        description: "Abstract painting of Dr. Babasaheb Ambedkar.",
        price: 499,
        originalPrice: 1250,
        discount: "60% OFF",
        rating: 4.8,
        reviews: "1.2k",
        image: ProdIMG1,
        id: 1,
        sizes: "12X16 Matte-Finish",
      },
      {
        name: "Besties Forever",
        description: "Portrait artwork of Besties Forever.",
        price: 599,
        originalPrice: 800,
        discount: "25% OFF",
        rating: 4.5,
        reviews: "987",
        image: ProdIMG2,
        id: 2,
        sizes: "12X16 Matte-Finish",
      },
      {
        name: "Birthday Frame",
        description: "Creative illustration of a Birthday Frame.",
        price: 599,
        originalPrice: 1499,
        discount: "60% OFF",
        rating: 4.3,
        reviews: "343",
        image: ProdIMG3,
        id: 3,
        sizes: "12X18 Matte-Finish",
      },
      {
        name: "Our Love Story",
        description: "Vibrant artwork of Our Love Story portrait.",
        price: 799,
        originalPrice: 999,
        discount: "25% OFF",
        rating: 4.4,
        reviews: "289",
        image: ProdIMG4,
        id: 4,
        sizes: "12X16 Matte-Finish",
      },
      {
        name: "Personalized Mosaic Portrait Frame",
        description: "Beautiful custom Mosaic Portrait Artwork.",
        price: 799,
        originalPrice: 1100,
        discount: "38% OFF",
        rating: 4.6,
        reviews: "320",
        image: ProdIMG5,
        id: 5,
        sizes: "12X18 Matte-Finish",
      },
    ],
  };

  const renderProducts = (categoryName, categoryProducts) => {
    if (!categoryProducts || !Array.isArray(categoryProducts)) {
      console.warn(`Category "${categoryName}" does not exist or is invalid.`);
      return <p className="text-gray-500">No products available in this category.</p>;
    }

    return categoryProducts.map((product) => (
      <div
        key={product.id}
        className="border rounded-lg shadow-lg p-4 hover:shadow-xl transition-shadow relative flex flex-col"
      >
        <div
          className="relative w-full h-64 overflow-hidden rounded-t-lg cursor-pointer"
          onClick={() => handleImageClick(product.image)}
        >
          <img
            src={product.image}
            alt={product.name}
            className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
            loading="lazy"
          />
        </div>

        <button
          className="absolute top-4 right-4 p-2 rounded-full text-xl transition"
          aria-label="Add to Wishlist"
          onClick={() => handleWishlistClick(product.id)}
        >
          <i
            className={`fa-heart ${wishlisted[product.id] ? "fas text-cyan-500" : "far text-red-500"}`}
          ></i>
        </button>

        <div className="flex-grow p-4">

          <h3 className="text-lg font-bold hover:text-purple-800 transition">
            {product.name}
          </h3>

          <p className="text-sm text-gray-600">{product.description}</p>
          <p className="text-sm text-gray-600 font-medium">Sizes: {product.sizes}</p>
          <div className="mt-3">
            <span className="text-xl font-semibold">₹{product.price}</span>{" "}
            <span className="line-through text-gray-500">₹{product.originalPrice}</span>{" "}
            <span className="text-green-500">{product.discount}</span>
          </div>
          <div className="mt-2 flex items-center">
            <i className="fas fa-star text-yellow-500"></i>
            <span className="ml-2 text-sm text-gray-500">★ {product.rating}</span>
            <span className="ml-2 text-sm text-gray-500">({product.reviews})</span>
          </div>
        </div>
        <button
          onClick={() => handleOrder(product)}
          className="mt-auto w-full bg-purple-800 text-white py-2 font-800 rounded-lg hover:bg-purple-600 transition"
        >
          Order Now
        </button>
      </div>
    ));
  };

  return (
    <>
      <Navbar className="mb-10" />
      <div className="bg-peach-100 mt-16 py-10 selection:bg-cyan-300 selection:text-cyan-700">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-center mb-8">Featured Artworks</h2>

          {Object.keys(products).map((categoryName) => (
            <div key={categoryName} className="mb-12">
              <h3 className="text-2xl font-semibold mb-4 capitalize">{categoryName}</h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                {renderProducts(categoryName, products[categoryName])}
              </div>
            </div>
          ))}
        </div>
      </div>

      {modalImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={handleCloseModal}
        >
          <div className="relative">
            <button
              className="absolute top-2 right-2 bg-purple-800 p-2 rounded-full shadow-lg"
              onClick={handleCloseModal}
              aria-label="Close"
            >
              <i className="fas fa-times text-white"></i>
            </button>
            <img
              src={modalImage}
              alt="Product"
              className="max-w-full max-h-screen object-contain"
            />
          </div>
        </div>
      )}

      <Footer />
    </>
  );
};

export default ProductShowcase;
